
import './App.css';
import Accueil from './pages/Accueil';
import {Route, Routes, useLocation} from "react-router-dom";
import Success from "./pages/Success";
import Error from "./pages/Error";

function App() {
  return (
        <Routes>
          <Route path="/">
            <Route index element={ <Accueil/>}/>
            <Route path="/success" element={ <Success/>}/>
            <Route path="/error" element={ <Error/>}/>
          </Route>
        </Routes>
  );
}

export default App;
