import React from 'react'
import Header from "../../components/Header";
import {useNavigate} from "react-router";
import "./index.css"

const Error = props => {
    let history = useNavigate()

    return (
        <div className="container">
            <Header/>
            <div className="error">
                <h2>Un problème est survenu lors du paiement, veuillez réessayer !</h2>
                <p onClick={() => history('/')}>Retournez faire un don</p>
            </div>
        </div>
    )
}

export default Error
