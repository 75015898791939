import React from 'react'
import Header from "../../components/Header";
import "./index.css"
import {useNavigate} from "react-router";

const Success = props => {
    let history = useNavigate()

    return (
        <div className="container">
            <Header/>
            <div className="success">
                <h2>Votre don a bien été pris en compte ! Merci beaucoup !</h2>
                <p onClick={() => history('/')}>Revenir sur la page de don</p>
            </div>
        </div>
    )
}

export default Success
