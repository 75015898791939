import React from "react";
import logo from "../../assets/images/Moofaaf_revisited_logo2.png";
import "./index.css";

const Header = (props) => {
  return (
    <div className="headerB">
        <div className="header">
      <img src={logo} alt="logo" />
      <div className="menu">
        <a href="https://moovingforafrica.org">Retourner sur le site</a>
      </div>
        </div>
    </div>
  );
};

export default Header;
