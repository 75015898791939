import React, {useState} from "react";
import Header from "../../components/Header";
import "./index.css";
import {Box, Button, Step, StepButton, Stepper, Typography} from "@mui/material";
import puzzleImage from "../../assets/images/puzzle.jpg"
import axios from "axios";
import countryCode from "../../ressources/country.json"

const steps = [
    'Informations personnelles',
    'Adresse de facturation',
    'Faire votre don',
];

const Accueil = (props) => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});

    const [firstname, setFirstname] = React.useState('')
    const [lastname, setLastname] = React.useState('')
    const [email, setEmail] = React.useState('')

    const [street, setStreet] = React.useState('')
    const [complement, setComplement] = React.useState('')
    const [zipCode, setZipCode] = React.useState('')
    const [city, setCity] = React.useState('')
    const [country, setCountry] = React.useState('')

    const [step1Completed, setStep1Completed] = React.useState(null)
    const [step2Completed, setStep2Completed] = React.useState(null)

    const [amount, setAmount] = React.useState('')
    const [customerId, setCustomerId] = React.useState('')

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 2;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const isFilled = (step) => {
        switch (step) {
            case 0:
                if (firstname && lastname && email) {
                    setStep1Completed(true)
                    return true
                } else {
                    setStep1Completed(false)
                    return false
                }
            case 1:
                if (street && zipCode && city && country) {
                    setStep2Completed(true)
                    return true
                } else {
                    setStep2Completed(false)
                    return false
                }
            default:
                return false
        }
    }
    const handleNext = () => {
        if (isFilled(activeStep)) {
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            const newActiveStep =
                isLastStep() && !allStepsCompleted()
                    ? // It's the last step, but not all steps have been completed,
                      // find the first step that has been completed
                    steps.findIndex((step, i) => !(i in completed))
                    : activeStep + 1;
            if (newActiveStep === 2) {
                createCustomer()
            }
            setActiveStep(newActiveStep);
        }
    };

    const handleBack = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = false;
        setCompleted(newCompleted);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };


    const createCustomer = async () => {
        let customer = {
            email: email,
            name: `${firstname} ${lastname}`,
            address: {
                line1: street,
                line2: complement,
                city: city,
                postal_code: zipCode,
                country: country
            }
        }

       let {data} = await axios.post('https://api.moofaf.fr/stripe/customer', customer)
        setCustomerId(data.id)
    }


    const handleContent = () => {
        if (activeStep === 0) {
            return <div>
                {step1Completed === false ? <p className="textForm error">*Merci de remplir toutes les informations</p> : null}
                <p className="textForm">Veuillez rentrer vos informations: Nom, Prénom et votre Adresse e-mail pour vous remercier !</p>
                <div className="formInfoPerso">
                    <input type="text" placeholder="Prénom" value={firstname}
                           onChange={(e) => setFirstname(e.target.value)}/>
                    <input type="text" placeholder="Nom de famille" value={lastname}
                           onChange={(e) => setLastname(e.target.value)}/>
                    <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)}/>
                </div>
            </div>
        } else if (activeStep === 1) {
            return <div>
                {step2Completed === false ? <p className="textForm error">*Merci de remplir toutes les informations</p> : null}
                <p className="textForm">Nous avons besoin de votre adresse, pour vous donner une facture !</p>
                <div className="formInfoPerso">
                    <input type="text" placeholder="Adresse" value={street}
                           onChange={(e) => setStreet(e.target.value)}/>
                    <input type="text" placeholder="Complément d'adresse" value={complement}
                           onChange={(e) => setComplement(e.target.value)}/>
                    <input type="text" placeholder="Code postal" value={zipCode}
                           onChange={(e) => setZipCode(e.target.value)}/>
                    <input type="text" placeholder="Ville" value={city} onChange={(e) => setCity(e.target.value)}/>
                    <select value={country} onChange={e => setCountry(e.target.value)}>
                        <option value="">Choisir un pays</option>
                        {countryCode.map(elt => (
                            <option value={elt.code}>{elt.country}</option>
                        ))}
                    </select>
                </div>
            </div>
        } else {
            return <div style={{width: "80%", margin: "auto"}}>
                <p className="textForm amount">Vous pouvez choisir le montant de votre don ! Nous vous remercions !</p>
                <div className="formInfoPerso amount">
                    <input type="number" min={1} placeholder="Montant" value={amount}
                           onChange={e => setAmount(e.target.value)}/>
                    <a href={`https://api.moofaf.fr/stripe/payment/${amount}/${customerId}`} className="payButton">Payer !</a>
                </div>
            </div>
        }
    }
    return (
        <div className="container">
            <Header/>
            <div className="accuelTextContainer">
                <h1>Faire un don à Mooving For Africa</h1>
                <Box sx={{width: '100%', margin: "auto"}}>
                    <Stepper nonLinear alternativeLabel activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepButton className="stepButton" onClick={handleStep(index)}>
                                    {label}
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {allStepsCompleted() ? (
                            <React.Fragment>
                                <Typography sx={{mt: 2, mb: 1}}>
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Box sx={{flex: '1 1 auto'}}/>
                                    <Button onClick={handleReset}>Reset</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Typography sx={{mt: 2, mb: 1}}>{handleContent()}</Typography>
                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, width: '80%', margin: "auto"}}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{mr: 1}}
                                    >
                                        Retour
                                    </Button>
                                    <Box sx={{flex: '1 1 auto'}}/>
                                    <Button onClick={handleNext} sx={{mr: 1, color: "green"}}>
                                        {activeStep === 2 ? null : "Suivant"}
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </div>
                </Box>
                <div className="Hero2">
                    <img className="imageHero" src={puzzleImage}/>
                    <div className="textHero">
                        <h2>Nos objectifs</h2>
                        <ul>
                            <li>Soutenir les petites et moyennes entrperises à impact social</li>
                            <li>De venir en aide à des populations vulnérables</li>
                            <li>D'organiser des événement séducatifs et culturel</li>
                        </ul>
                        <h4>Les seules ressources de l'association sont les dons des particuiers et des entreprises qui
                            adhèrent aux valeurs de notre association:</h4>
                        <ul>
                            <li><strong>la Solidarité</strong> à traers notre volonté de préserver et d'améliorer le
                                bien-être des
                                populations
                            </li>
                            <li><strong>l'Indépendance</strong> de Moofaf qui s'exprime à travers sa liberté d'action et
                                de pensée
                            </li>
                            <li><strong>la Créativité</strong>: comme remède pour imaginer les solutions aux défis de la
                                société
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Accueil;
